import React from 'react';


function ShowResults(props)
{
    const isDataArray=Array.isArray(props.data);

    const now = new Date();
    const timeString = 'Executed at '+ now.toLocaleTimeString(); 

   // const dataArray = isdataArray ? props.data : [];
   if (isDataArray && props.data.length === 0) {
    return <div><pre>{timeString}<br/></pre>[]</div>;
}
  //  console.log(JSON.stringify(dataArray));
console.log('props.data='+JSON.stringify(props.data) + isDataArray);
    return (
        <div>
            <pre>{timeString}<br/></pre>
            {isDataArray ? (
                <div style={{ backgroundColor: 'lightgray' }}>
                    {props.data.map((item, index) => (
                        <div key={index}>
                            <h3>Item {index + 1}</h3>
                            <pre>{JSON.stringify(item, null, 2)}</pre>
                        </div>
                    ))}
                </div>
            ) : (
                props.data === '' ? (
                    <div>No Results</div>
                ) : (
                    <div style={{ backgroundColor: 'lightgray' }}>
                        <pre>{JSON.stringify(props.data, null, 2)}</pre>
                    </div>
                )
            )}
        </div>
    );
}
export default ShowResults;