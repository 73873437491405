
import React, { useEffect, useContext,useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import './App.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PickleLogo from './pickle.png';
import Connection from './Connection';
import ShowResults from './ShowResults';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from './AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay,faAngleDoubleDown,faAngleDoubleUp,faTrashCan,faUpDown } from '@fortawesome/free-solid-svg-icons';
import Stack from 'react-bootstrap/Stack';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Accordion from 'react-bootstrap/Accordion';

import availableApis from './b'; 


function App() {

  const {
    profile,
    setProfile,
    setUser,
    user,
  } = useContext(AppContext);

  const [TestPlan, setTestPlan] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [CurrentEdit, setCurrentEdit]=useState(0);
  const [CommandResults, setResults]=useState("");

  const [APIuser,setAPIuser]=useState("");
  const [APIpassword,setAPIpassword]=useState("");

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const [formFields, setFormFields] = useState([
    { key: 'field1', value: '' },{ key: 'field2', value: '123' }
  ]);

  const handleLogin = async googleData => {
    console.log('googledata='+JSON.stringify(googleData));
    const res = await fetch("http://localhost:5000/api/auth/google", {
        method: "POST",
        body: JSON.stringify({
        token: googleData.access_token
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    const data = await res.json()
    console.log('return data from api='+JSON.stringify(data));
    return data;
    // store returned user somehow
  }

  const login =  useGoogleLogin({
    onSuccess: (codeResponse) => {
    //  console.log('codeReponse='+JSON.stringify(codeResponse));
      setUser(codeResponse);
      handleLogin(codeResponse);

    },
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(() => {
    const fetchProfile = async () => {
      if (user) {
        try {
          const res = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          });
          setProfile(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchProfile();
  }, [user, setProfile]);


  const handleLogout = () => {
    googleLogout();
    setProfile(null);
  };
  //This function checks for empty values in the values, if its empty we can't execute, we change the backcolor
  function checkEmptyValues(data) {
    const emptyFields = [];
  

  for (const key in data.values) {
    if (data.values.hasOwnProperty(key) && data.values[key] === '') {
        // If the value is an empty string, check if the field is optional
        if (!data.hasOwnProperty("optional") || !data.optional.includes(key)) {
            // If "optional" does not exist or key is not in "optional", add to emptyFields
            emptyFields.push(key);
        }
      }
    }

   // console.log('CHECKING ' + emptyFields.length + '  ' + data.summary);
    return emptyFields.length > 0 ? false : true;
  }
  function addTest(item)
  {
    //always add it at the end so we can add an index and id2 used for unique key in the div
      const newItem = { ...item, id2: uuidv4(),index: uuidv4(),configured:checkEmptyValues(item) };
      setTestPlan([...TestPlan, newItem]);
  }
  function editItem(index)
  {
    const currentIndex = TestPlan.findIndex(TestPlan => TestPlan.index === index);
    if (currentIndex<0) return;
    setCurrentEdit(index); //keeps track of which one is being edited for Saving
    setFormFields(Object.keys(TestPlan[currentIndex].values).map(key => {
      return { key: key, value: TestPlan[currentIndex].values[key] };
    }));

    handleShowEdit(true);

  }
  
  const handleAPIPasswordChange = (event) => {
    setAPIpassword(event.target.value);
   // console.log('set new p'+newPassword);
};    
const handleAPIUserChange = (event) => {
  setAPIuser(event.target.value);
};
    // Handle form submission
    function handleSubmitEdit() {
     // event.preventDefault();
      const valuesObject = formFields.reduce((obj, item) => {
        obj[item.key] = item.value;
        return obj;
      }, {});

      console.log('Form Data:',JSON.stringify(valuesObject));

      const currentIndex = TestPlan.findIndex(TestPlan => TestPlan.index === CurrentEdit);
      const newTestPlan = [...TestPlan];
      newTestPlan[currentIndex].values=valuesObject;
      newTestPlan[currentIndex].configured=checkEmptyValues(newTestPlan[currentIndex])
      setTestPlan(newTestPlan);

      handleCloseEdit();
    };
     // Handle input change for each dynamic field
  const handleInputChange = (index, event) => {
    const newFormFields = [...formFields];
    newFormFields[index].value = event.target.value;
    setFormFields(newFormFields);
  };
  function moveUp(index)
  {
      // Find the current index of the item with the given index value
      const currentIndex = TestPlan.findIndex(TestPlan => TestPlan.index === index);
      const newPlan=[...TestPlan];
      // Check if the item can be moved up (i.e., it's not already at the top)
      if (currentIndex > 0) {
        // Swap the item with the one above it
        const temp = newPlan[currentIndex - 1];
        newPlan[currentIndex - 1] = newPlan[currentIndex];
        newPlan[currentIndex] = temp;
        setTestPlan(newPlan);
      }
  }
 
  async function runItem(index) {
    // Find the index of the item with the given id
    const currentIndex = TestPlan.findIndex(item => item.index === index);

    const contents = TestPlan[currentIndex];// { title: 'React POST Request Example' };
/*{"apiname":"/api/atlas/v2/groups/{groupId}/streams","httpmethod":"get","id":1,"description":"Returns all stream instances for the specified project.","operationId":"listStreamInstances","headers":["Accept: application/vnd.atlas.2023-02-01+json"],"summary":"Return All Project Stream Instances","body":"","values":{"groupId":""},"id2":"fd2e7fbb-5e2d-43bb-b9f3-6ac8dbcfb12d","index":"6f2f412d-98e8-4c35-960e-1f618d90f74a","configured":false}*/
    contents.user=APIuser;
  contents.password=APIpassword;
    
    console.log('runcommand='+process.env.REACT_APP_API_URL); //JSON.stringify(contents));
  
    axios.post( process.env.REACT_APP_API_URL, { //'http://localhost:5000/api/runcommand', {
    //    headers: {
    //   Authorization: `Bearer ${user.access_token}`,
    //   Accept: 'application/json'
    // },
  body:
  
    JSON.stringify(contents)
  
  })

    .then(response => {
      console.log(response.data);
      setResults(response.data);
    })
    .catch(error => {
      console.error(error);
    });
    
  }
  function moveDown(index) {
    const currentIndex = TestPlan.findIndex(item => item.index === index);
    const newPlan = [...TestPlan];
  
    if (currentIndex < newPlan.length - 1) {
      const temp = newPlan[currentIndex + 1];
      newPlan[currentIndex + 1] = newPlan[currentIndex];
      newPlan[currentIndex] = temp;
      setTestPlan(newPlan);
    }
  }
  function deleteItem(index) {
    // Find the index of the item with the given id
    const currentIndex = TestPlan.findIndex(item => item.index === index);
  
    // If the item is found, remove it from the array
    if (currentIndex !== -1) {
      const newPlan = [
        ...TestPlan.slice(0, currentIndex), // Items before the item to be deleted
        ...TestPlan.slice(currentIndex + 1) // Items after the item to be deleted
      ];
  
      // Update the state with the new array
      setTestPlan(newPlan);
    }
  }

  return (<div style={{ backgroundColor: 'white', margin: '3px'}}>
     <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            {formFields.map((field, index) => (
              <Form.Group key={index} controlId={`formField${index}`}>
                <Form.Label>{field.key}</Form.Label>
                <Form.Control
                  type="text"
                  value={field.value}
                  onChange={(event) => handleInputChange(index, event)}
                  placeholder={`Enter value for ${field.key}`}
                />
              </Form.Group>
            ))}
           
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmitEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home"><img src={PickleLogo} width={'50px'} height={'50px'} alt="Logo" /></Navbar.Brand>&nbsp;&nbsp;&nbsp;<span className='logostyle'>PickleShot API</span>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
             
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Accordion defaultActiveKey="0" className='accord'>
      <Accordion.Item eventKey="0"  style={{backgroundColor:'lightgray'}}>
        <Accordion.Header>API Authentication</Accordion.Header>
        <Accordion.Body>
      <Connection username={APIuser} password={APIpassword} onAPIUserChange={handleAPIUserChange} onAPIPasswordChange={handleAPIPasswordChange}/>
      </Accordion.Body>
      </Accordion.Item>
      </Accordion>
   <Container style={{margin: '5px'}} fluid>
        <Row>
          <Col style={{ border: '1px solid' }} sm={3}>Available APIs</Col>
          <Col style={{ border: '1px solid'}} sm={9}>Test Plan</Col>
        </Row>
        <Row>
          <Col style={{ border: '1px solid',height:'75vh',overflowY:'auto' }} sm={3}>
          {availableApis.map((item) => (<div key={item.id} >
        <Button className='APIClassList' key={item.description} onClick={() => addTest(item)}>
          <b>{item.summary}</b>&nbsp;({item.httpmethod.toUpperCase()})
        </Button>
        </div>
      ))}
          </Col>
          <Col  style={{
            border: '1px solid',
            height: '75vh', // Full height of the viewport
            overflowY: 'auto', // Scrollbar when content overflows
            backgroundColor: '#f8f9fa', // Optional: background color for the column
            padding: '20px' // Optional: padding for content inside the column
          }} sm={9}>
          <PanelGroup autoSaveId="resultsPanel" direction="vertical">
          <Panel defaultSize={25} minSize={20} style={{overflow:'auto'}}>
         
          {TestPlan.map((item) => (<div key={item.id2} style={{border:'1px solid black',padding:'5px'}}>
            <Stack gap={0} direction="horizontal">
            <Stack gap={0}>
            <Button className='APIActions' onClick={()=> moveUp(item.index)}><FontAwesomeIcon icon={faAngleDoubleUp} /></Button>
            <Button className='APIActions' onClick={()=>deleteItem(item.index)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></Button>
            <Button className='APIActions' onClick={()=> moveDown(item.index)}><FontAwesomeIcon icon={faAngleDoubleDown} /></Button>
            </Stack>
            <Button className='APIClass' style={{backgroundColor: (item.configured)?'green':'red'}} key={item.index} onClick={() => editItem(item.index)}>
          <b>{item.summary}</b>&nbsp;({item.httpmethod.toUpperCase()})<br/>
          {Object.keys(item.values).map((key, index) => (<Row><Col style={{display:'flex', justifyContent:'left'}}>{key}</Col><Col style={{display:'flex', justifyContent:'left'}}>{item.values[key] || '<empty>'}</Col></Row>))}
        </Button><br/>   <Button className='APIActions' style={{height:'110px'}} onClick={()=>runItem(item.index)}><FontAwesomeIcon icon={faPlay} /></Button>
            </Stack>
    
            </div>))}
      
            </Panel>
            <PanelResizeHandle className="handle">
            {/* custom handlebar */}
            <FontAwesomeIcon icon={faUpDown} />
          </PanelResizeHandle>
          <Panel defaultSize={25} minSize={20} style={{overflow:'auto'}}>
          <Col style={{
            border: '1px solid',
            height: '90%', // Full height of the viewport
            width:'100%',
            overflowY:'auto', // Scrollbar when content overflows
            backgroundColor: '#f8f9fa', // Optional: background color for the column
            padding: '20px' // Optional: padding for content inside the column
          }} sm={9}><ShowResults data={CommandResults}/></Col>
          </Panel>
          </PanelGroup>
          </Col>
        </Row>
      </Container>

  </div>)
}


export default App;

/* {profile ? (
                <div>
                  <p>Welcome, {profile.name}&nbsp;&nbsp;&nbsp;<Button onClick={handleLogout}>Log out</Button></p>
                </div>
              ) : (
                <button onClick={() => login()}>Sign in with Google 🚀 </button>
              )}*/