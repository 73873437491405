const restapilist= [
    {
      "apiname": "/api/atlas/v2/groups/{groupId}/streams",
      "httpmethod":"get",
      "id":1,
      "description": "Returns all stream instances for the specified project.",
      "operationId": "listStreamInstances",
      "headers": [ "Content-Type: application/json","Accept: application/vnd.atlas.2024-05-30+json"],
      "summary": "Return All Stream Processor Instances",
      "values":{"groupId":""}
    },
    {
       "apiname": "/api/atlas/v2/groups/{groupId}/streams",
       "httpmethod":"post",
       "id":2,
       "description": "Creates one stream instance in the specified project. To use this resource, the requesting API Key must have the Project Data Access Admin role, Project Owner role or Project Stream Processing Owner role.",
       "operationId": "createStreamInstance",
       "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2023-02-01+json"],
       "summary": "Create Stream Processor Instance",
       "values":{"groupId":"","cloudProvider":"AWS","region":"VIRGINIA_USA","tenantName":"","tier":"SP30"},
       "template":{"dataProcessRegion": {"cloudProvider":"{cloudProvider}","region": "{region}"},"name":"{tenantName}","streamConfig": {"tier": "{tier}"}} },
    {
      "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}",
      "httpmethod":"delete",
        "id":3,
        "description": "Delete one stream instance in the specified project. To use this resource, the requesting API Key must have the Project Data Access Admin role, Project Owner role or Project Stream Processing Owner role.",
        "operationId": "deleteStreamInstance",
        "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2023-02-01+json"],
        "summary": "Delete Stream Processor Instance",
        "values":{"groupId":"","tenantName":""}
        },
        { "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}",
            "httpmethod":"get",
           "id":4,
          "description": "Returns the details of one stream instance within the specified project. To use this resource, the requesting API Key must have the Project Data Access roles, Project Owner role or Project Stream Processing Owner role.",
          "operationId": "getStreamInstance",
          "summary": "Return Stream Processor Instance",
          "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2023-02-01+json"],
          "values":{"groupId":"","tenantName":""}
        },
        { "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}",
           "httpmethod":"patch",
           "id":5,
          "description": "Update one stream instance in the specified project.",
          "operationId": "updateStreamInstance",
          "summary": "Update Stream Processor Instance",
          "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2023-02-01+json"],
          "values":{"groupId":"","tenantName":"","cloudProvider":"AWS","region":"VIRGINIA_USA"},
          "template":{"cloudProvider":"{cloudProvider}","region": "{region}"} 
        },
        { "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}/connections",
           "httpmethod":"get",
           "id":7,
          "description": "Returns all connections of the stream instance for the specified project.",
          "operationId": "listStreamConnections",
          "summary": "Return All Connections of SPI",
          "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2023-02-01+json"],
          "values":{"groupId":"","tenantName":""}
        },
        { "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}/connections",
           "httpmethod":"post",
           "id":8,
          "description": "Creates one connection (SAMPLE) for a stream instance in the specified project.",
          "operationId": "createStreamConnection(SAMPLE)",
          "summary": "Create SAMPLE connection",
          "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2023-02-01+json"],
          "values":{"groupId":"","tenantName":""},
          "template":{"name":"sample_stream_solar","type":"Sample"}
        },
        { "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}/connections",
           "httpmethod":"post",
           "id":9,
          "description": "Creates KAFKA connection for a stream instance",
          "operationId": "createStreamConnection(KAFKA)",
          "summary": "Create KAFKA connection",
          "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2023-02-01+json"],
          "values":{"groupId":"","tenantName":"","connectionName":"","mechanism":"PLAIN","authusername":"","authpassword":"","bootstrapServers":"","networkingName":"","networkingType":"","authKey":"","dnsName":"","brokerPublicCertificate":"","protocol":""},
          "optional":["networkingName","networkingType","authKey","dnsName","brokerPublicCertificate","protocol"],
          "template":{"name":"{connectionName}","type":"Kafka",
            "authentication": {
              "mechanism": "{mechanism}",
              "password": "{authpassword}",
              "username": "{authusername}"
            },
            "bootstrapServers": "{bootstrapServers}",
            "networking": {
              "access": {
                "name": "{networkingName}",
                "type": "{networkingType}"
              }
            },
            "proxyInfo": {
              "authKey": "{authKey}",
              "dnsName": "{dnsName}"
            },
            "security": {
              "brokerPublicCertificate": "{brokerPublicCertificate}",
              "protocol": "{protocol}"
            }
          }
        },
        { "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}/connections",
           "httpmethod":"post",
           "id":10,
          "description": "Create CLUSTER connection for a stream instance",
          "operationId": "createStreamConnection(CLUSTER)",
          "summary": "Create CLUSTER connection",
          "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2023-02-01+json"],
          "values":{"groupId":"","tenantName":"","connectionName":"","clusterName":"","role":"atlasAdmin","roleType":"BUILT_IN"},
          "optional":["networkingName","networkingType","authKey","dnsName","brokerPublicCertificate","protocol"],
          "template":{"name":"{connectionName}","type":"Cluster","clusterName":"{clusterName}",
            "dbRoleToExecute": {
              "role": "{role}",
              "type": "{roleType}"
            }
          }
        },   {
            "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}/connections/{connectionName}",
            "httpmethod":"delete",
              "id":11,
              "description": "Delete one connection of the specified stream instance",
              "operationId": "deleteStreamConnection",
              "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2023-02-01+json"],
              "summary": "Delete Connection",
              "values":{"groupId":"","tenantName":"","connectionName":""}
              },   {
            "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}/connections/{connectionName}",
            "httpmethod":"get",
              "id":12,
              "description": "Returns the details of one stream connection ",
              "operationId": "getStreamConnection",
              "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2023-02-01+json"],
              "summary": "Return One Connection",
              "values":{"groupId":"","tenantName":"","connectionName":""}
              },
        { "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}/connections/{connectionName}",
           "httpmethod":"patch",
           "id":13,
          "description": "Update CLUSTER connection for a stream instance",
          "operationId": "updateStreamConnection(CLUSTER)",
          "summary": "Update CLUSTER connection",
          "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2023-02-01+json"],
          "values":{"groupId":"","tenantName":"","connectionName":"","clusterName":"","role":"atlasAdmin","roleType":"BUILT_IN"},
          "optional":["networkingName","networkingType","authKey","dnsName","brokerPublicCertificate","protocol"],
          "template":{"name":"{connectionName}","type":"Cluster","clusterName":"{clusterName}",
            "dbRoleToExecute": {
              "role": "{role}",
              "type": "{roleType}"
            }
          }
        },
        { "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}/processors",
           "httpmethod":"get",
           "id":6,
          "description": "Returns all Stream Processors within the specified stream instance.",
          "operationId": "listStreamProcessors",
          "summary": "Return All Stream Processors",
          "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2024-05-30+json"],
          "values":{"groupId":"","tenantName":""}
        },
        { "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}/processor",
           "httpmethod":"post",
           "id":14,
          "description": "Create Stream Processor",
          "operationId": "createStreamProcessor",
          "summary": "Create Stream Processor",
          "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2024-05-30+json"],
          "values":{"groupId":"","tenantName":"","SPName":"","pipeline":"","dlqColl":"","dlqConnectionName":"","dlqDb":""},
          "optional":["dlqColl","dlqConnectionName","dlqDb"],
          "template":{"name":"{SPName}",
            "options": {
              "dlq": {
                "coll":"{dlqColl}",
              "connectionName": "{dlqConnectionName}",
              "db":"{dlqDb}" }
            }, "pipeline":"{pipeline}"
          }
        },
        { "apiname": "/api/atlas/v2/groups/{groupId}/streams/{tenantName}/processor/{processorName}",
           "httpmethod":"delete",
           "id":15,
          "description": "Delete Stream Processor",
          "operationId": "deleteStreamProcessor",
          "summary": "Delete Stream Processor",
          "headers":["Content-Type: application/json","Accept: application/vnd.atlas.2024-05-30+json"],
          "values":{"groupId":"","tenantName":"","processorName":""}
          
        },

  ];

export default restapilist;

//   "body":"\"dataProcessRegion\": \"{\"cloudProvider\": \"{cloudProvider}\",\"region\": \"{region}\" },\"name\": \"{tenantName}\",\"streamConfig\": {\"tier\": \"{tier}\"}}",
    