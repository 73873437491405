import React from 'react';
import Form from 'react-bootstrap/Form';

function Connection(props)
{
    return (<div  style={{backgroundColor:'lightgray'}}>   
   <Form>
      <Form.Group  controlId="formBasicUsername">
        <Form.Label>API Username</Form.Label>
        <Form.Control type="username" placeholder="Enter API Username" onChange={props.onAPIUserChange} />
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>API Password</Form.Label>
        <Form.Control type="password" placeholder="Enter API Password" onChange={props.onAPIPasswordChange} />
      </Form.Group>
      </Form></div>)
}
export default Connection;