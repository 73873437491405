// AppContext.js
import React, { createContext, useState } from 'react';
//import restapilist from './b'; 

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
 // const [availableApis, setAvailableApis] = useState(restapilist);
  const [testPlanApis, setTestPlanApis] = useState([]);
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);

  /*const moveBox = (fromIndex, toIndex) => {
    const updatedApis = [...availableApis];
    const [movedApi] = updatedApis.splice(fromIndex, 1);
    updatedApis.splice(toIndex, 0, movedApi);
    setAvailableApis(updatedApis);
  };*/

  return (
    <AppContext.Provider
      value={{
      //  availableApis,
      //  setAvailableApis,
        testPlanApis,
        setTestPlanApis,
        user,
        setUser,
        profile,
        setProfile
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
